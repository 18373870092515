import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  OnDestroy,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

declare var JSONEditor: any;

@Component({
  selector: 'cms-json-editor, json-editor[formControlName]',
  template:
      '<div>' +
        '<i (click)="toggleFullScreen()" class="pointer json-editor-maximize-icon g-icon g-icon-maximize"></i>' +
      '</div>',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./json-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JsonEditorComponent),
      multi: true,
    }
  ]
})
export class JsonEditorComponent implements ControlValueAccessor, OnDestroy, AfterViewInit {
  @HostBinding('class.full-screen-editor') fullScreen: boolean = false;
  private jsonEditorTextarea: HTMLTextAreaElement;
  private jsonEditorTextareaStyle: string;
  private editor: any;
  private propagateChange = (_: any) => { };

  constructor(
      private renderer: Renderer2,
      private rootElement: ElementRef
  ) {
    this.editor = new JSONEditor(
        this.rootElement.nativeElement,
        {
          mode: 'text',
          onChange: () => {
            try {
              this.propagateChange(JSON.parse(
                  this.editor.getText()
              ));
            } catch (e) {
              // we haz error
              this.propagateChange('');
            }
          },
          onError: (error) => {
            console.log('onError');
            console.log(error);
          }
        }
    );
  }

  public toggleFullScreen() {
    if (this.jsonEditorTextarea) {
      if (!this.fullScreen) {
        this.jsonEditorTextareaStyle = this.jsonEditorTextarea.getAttribute('style');
        this.renderer.setAttribute(this.jsonEditorTextarea, 'style', '');
      } else {
        this.renderer.setAttribute(this.jsonEditorTextarea, 'style', this.jsonEditorTextareaStyle);
      }
    }
    this.fullScreen = !this.fullScreen;
  }

  // this is the initial value set to the component
  public writeValue(obj: any) {
    try {
      this.editor.set(obj);
    } catch (e) {
      console.log(e);
    }
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  // not used, used for touch input
  public registerOnTouched() { }

  ngAfterViewInit(): void {
    const jsonEditorTextArea = this.rootElement.nativeElement.querySelectorAll('textarea.jsoneditor-text');

    if (jsonEditorTextArea[0]) {
      this.jsonEditorTextarea = jsonEditorTextArea[0];
    }
  }

  ngOnDestroy() {
    this.editor.destroy();
  }

}
