import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import 'local-operators';
import {FileUploadModule} from '@nsoft/ng2-file-upload';
import { NuMonacoEditorModule } from '@ng-util/monaco-editor';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {CmsCoreModule} from './core/core.module';
import {CmsLoginModule} from './login/login.module';
import {CmsSiteConfigLoader, SITE_CONFIG, SiteConfig} from './core/site-config.service';
import {CmsArticlesService} from './articles/shared/articles.service';
import {CmsAppsService} from 'app/apps/shared/apps.service';
import {CmsNewCategoryComponent} from './articles/new-category/new-category.component';
import {CmsEditCategoryComponent} from './articles/edit-category/edit-category.component';
import {CmsEditArticleTranslationComponent} from 'app/articles/edit-article-translation/edit-article-translation.component';
import {CmsEditArticleComponent} from './articles/edit-article/edit-article.component';
import {CmsResourcesEditComponent} from './resources/edit-resource/resources-edit.component';
import {CmsEditResourceTranslationComponent} from './resources/edit-resource-translation/edit-resource-translation.component';
import {CmsResourcesUploadComponent} from './resources/upload-resource/resources-upload.component';
import {CmsResources} from 'app/resources/shared/resources.service';
import {CmsPageNotFoundComponent} from './page-not-found/page-not-found.component';
import {CmsEditCategoryTranslationComponent} from './articles/edit-category-translation/edit-category-translation.component';
import {CmsEditContainerComponent} from './resources/edit-container/edit-container.component';
import {CmsNewContainerComponent} from './resources/new-container/new-container.component';
import {CmsApplicationSettingsEditComponent} from './application-settings/edit/application-settings-edit.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CmsSharedModule} from './shared/shared.module';
import {CmsNewArticleComponent} from './articles/new-article/new-article.component';
import {CmsNewAppComponent} from 'app/apps/new-app/new-app.component';
import {CmsEditAppComponent} from './apps/edit-app/edit-app.component';
import {CmsEditMenuTemplateItemComponent} from './menus/edit-menu-template-item/edit-menu-template-item.component';
import {CmsAddMenuTemplateItemComponent} from './menus/add-menu-template-item/add-menu-template-item.component';
import {CmsAddMenuItemComponent} from './menus/add-menu-item/add-menu-item.component';
import {CmsEditMenuItemComponent} from './menus/edit-menu-item/edit-menu-item.component';
import {CmsDeleteResourceComponent} from './resources/delete-resource/delete-resource.component';
import {CmsApplicationSettingsNewComponent} from './application-settings/new/application-settings-new.component';
import {CmsAppSettingSnapshotsComponent} from './application-settings/shared/setting-snapshots/app-setting-snapshots.component';
import {CmsAppSettingSnapshotsRollbackComponent} from './application-settings/shared/setting-snapshots-rollback/app-setting-snapshots-rollback.component';
import {CmsApplicationSettingsHistoryComponent} from './application-settings/history/application-settings-history.component';
import {CmsAppTemplateSettingsHistoryComponent} from './app-template-settings/history/app-template-settings-history.component';
import {CmsTranslateService} from './shared/translate.service';
import {CmsEditTemplateItemInMenuComponent} from './menus/edit-template-item-in-menu/edit-template-item-in-menu.component';
import {CmsDeleteMenuItemComponent} from './menus/delete-menu-item/delete-menu-item.component';
import {CmsDeleteMenuTranslationComponent} from './menus/delete-menu-translation/delete-menu-translation.component';
import {CmsAddTicketBlockComponent} from './ticket-layout-creator/add-ticket-block/add-ticket-block.component';
import {CmsUserSettingsEditComponent} from './user-settings/edit/user-settings-edit.component';
import {localStorageWrapper} from 'app/shared/localStorage/localStorageWrapper';
import {CmsNewPageComponent} from 'app/pages/new-page/new-page.component';
import {CmsNewPageTemplateComponent} from './pages/new-page-template/new-page-template.component';
import {CmsEditPageTemplateComponent} from './pages/edit-page-template/edit-page-template.component';
import {CmsTranslatePageTemplateComponent} from './pages/translate-page-template/translate-page-template.component';
import * as Sentry from '@sentry/angular';
import {environment} from '../environments/environment';
import {versions} from '../environments/versions';
import {CmsEditPageComponent} from './pages/edit-page/edit-page.component';
import {CmsTranslatePageComponent} from './pages/translate-page/translate-page.component';
import {AddPageWidgetComponent} from './pages/add-page-widget/add-page-widget.component';
import {ConfigurePageWidgetArticleComponent} from './pages/configure-page-widget-article/configure-page-widget-article.component';
import {CmsAuditsComponent} from './audits/audits/audit.component';
import {CmsAddMarketingSlotItemComponent} from './marketing-slots/add-marketing-slot-item/add-marketing-slot-item.component';
import {CmsEditMarketingSlotItemComponent} from './marketing-slots/edit-marketing-slot-item/edit-marketing-slot-item.component';
import {CmsResourcePickerModalComponent} from 'app/shared/resource-picker/reference-picker-modal/resource-picker-modal.component';
import {
    CmsTranslateMarketingSlotItemComponent
} from './marketing-slots/translate-marketing-slot-item/translate-marketing-slot-item.component';
import {CmsAppTemplatesService} from './app-templates/shared/app-templates.service';
import {CmsAppTemplateSettingsService} from './app-template-settings/shared/app-template-settings.service';
import {CmsAppTemplateSettingsEditComponent} from 'app/app-template-settings/edit-app-template-setting/edit-app-template-setting.component';
import {CmsAppTemplateSettingsNewComponent} from 'app/app-template-settings/new-app-template-setting/new-app-template-setting.component';
import {CmsNewAppTemplateComponent} from './app-templates/new-app-template/new-app-template.component';
import {CmsEditAppTemplateComponent} from './app-templates/edit-app-template/edit-app-template.component';
import {CmsAddListItemComponent} from './lists/add-list-item/add-list-item.component';
import {CmsEditListItemComponent} from './lists/edit-list-item/edit-list-item.component';
import { CmsInterceptor } from './core/CmsInterceptor';
import { CmsArchiveTemplateSettingComponent } from './app-template-settings/archive-template-setting/archive-template-setting.component';
import { CmsArchiveSettingComponent } from './application-settings/archive-setting/archive-setting.component';
import { CmsFragmentHistoryComponent } from './ticket-layout-creator/fragment-history/fragment-history.component';

import { Angulartics2Module } from 'angulartics2';
import { DatePipe } from '@angular/common';
import { CmsFragmentRollbackDialogComponent } from './ticket-layout-creator/fragment-history/fragment-rollback-dialog.component';
import { CmsDeleteTicketBlockPromptComponent } from './ticket-layout-creator/delete-ticket-block-prompt/cms-delete-ticket-block-prompt.component';

export function appInitFactory(config: CmsSiteConfigLoader) {
    localStorageWrapper();
    return () => config.load();
}

// ng-cli won't compile without export (https://github.com/angular/angular-cli/issues/4440#issuecomment-285327136), it's resolved in newer
// versions
export const localHostCheckRegex = /.*:[ 0-9]*/g;
const isLocalDevelopment = !!location.host.match(localHostCheckRegex);
// Disabling sentry relies on local host check (webpack dev server doesn't seem to run on port 80 so it's safe to assume that host will have
// port) and env config
const shouldEnableSentry = environment.production && !isLocalDevelopment;

if (shouldEnableSentry) {
	Sentry.init({
		dsn: 'https://e486a0ada5184644a48a30b7264db644@o73276.ingest.sentry.io/190806',
		environment: environment.name,
		release: `7CMS-v${versions.version}`,
		attachStacktrace: true,
		initialScope: {
			tags: { git_commit: versions.revision },
		},
	});
}

export class SentryErrorHandler implements ErrorHandler {
	handleError(err: any): void {
		Sentry.withScope((scope) => {
			scope.setExtra(
				'serverName',
				environment.gravityCmsApi.replace(/^https?:\/\//i, ''),
			);
			Sentry.captureException(err);
		});
		console.error(err);
	}
}

const errorHandlerProvider = shouldEnableSentry ?
    {
        provide: ErrorHandler,
        useClass: SentryErrorHandler
    } :
    {
        provide: 'DefaultErrorHandler',
        useExisting: 'ErrorHandler'
    };

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CmsSharedModule,
        CmsLoginModule,
        CmsCoreModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FileUploadModule,
        NuMonacoEditorModule.forRoot({
          defaultOptions: { scrollBeyondLastLine: false },
        }),
        Angulartics2Module.forRoot({
            developerMode: !environment.googleAnalytics,
            pageTracking: {
                clearIds: true,
                clearQueryParams: true
            }
        }),
    ],
    providers: [
        CmsSiteConfigLoader,
        CmsArticlesService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitFactory,
            deps: [CmsSiteConfigLoader],
            multi: true
        },
        {provide: SITE_CONFIG, useValue: SiteConfig},
        CmsAppsService,
        CmsAppTemplatesService,
        CmsAppTemplateSettingsService,
        CmsResources,
        CmsTranslateService,
        errorHandlerProvider,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CmsInterceptor,
            multi: true,
        },
        DatePipe
    ],
    declarations: [
        AppComponent,
        AddPageWidgetComponent,
        ConfigurePageWidgetArticleComponent,
        CmsNewCategoryComponent,
        CmsEditCategoryComponent,
        CmsEditCategoryTranslationComponent,
        CmsNewArticleComponent,
        CmsNewAppComponent,
        CmsEditAppComponent,
        CmsNewAppTemplateComponent,
        CmsEditAppTemplateComponent,
        CmsEditArticleTranslationComponent,
        CmsEditArticleComponent,
        CmsResourcesEditComponent,
        CmsEditResourceTranslationComponent,
        CmsResourcesUploadComponent,
        CmsEditMenuTemplateItemComponent,
        CmsAddMenuTemplateItemComponent,
        CmsEditMenuItemComponent,
        CmsEditTemplateItemInMenuComponent,
        CmsDeleteMenuItemComponent,
        CmsDeleteMenuTranslationComponent,
        CmsAddMenuItemComponent,
        CmsPageNotFoundComponent,
        CmsEditContainerComponent,
        CmsNewContainerComponent,
        CmsApplicationSettingsEditComponent,
        CmsApplicationSettingsNewComponent,
        CmsAppSettingSnapshotsComponent,
        CmsAppSettingSnapshotsRollbackComponent,
        CmsApplicationSettingsHistoryComponent,
        CmsAppTemplateSettingsHistoryComponent,
        CmsAppTemplateSettingsEditComponent,
        CmsAppTemplateSettingsNewComponent,
        CmsDeleteResourceComponent,
        CmsNewPageComponent,
        CmsNewPageTemplateComponent,
        CmsEditPageTemplateComponent,
        CmsTranslatePageTemplateComponent,
        CmsEditPageComponent,
        CmsTranslatePageComponent,
        CmsUserSettingsEditComponent,
        CmsAuditsComponent,
        CmsAddMarketingSlotItemComponent,
        CmsEditMarketingSlotItemComponent,
        CmsTranslateMarketingSlotItemComponent,
        CmsResourcePickerModalComponent,
        CmsAddListItemComponent,
        CmsEditListItemComponent,
        CmsAddTicketBlockComponent,
        CmsFragmentHistoryComponent,
        CmsFragmentRollbackDialogComponent,
        CmsDeleteTicketBlockPromptComponent,
    ],
    entryComponents: [
        AddPageWidgetComponent,
        ConfigurePageWidgetArticleComponent,
        CmsEditMenuTemplateItemComponent,
        CmsAddMenuTemplateItemComponent,
        CmsEditTemplateItemInMenuComponent,
        CmsDeleteMenuItemComponent,
        CmsDeleteMenuTranslationComponent,
        CmsEditMenuItemComponent,
        CmsAddMenuItemComponent,
        CmsDeleteResourceComponent,
        CmsUserSettingsEditComponent,
        CmsAppSettingSnapshotsRollbackComponent,
        CmsAuditsComponent,
        CmsAddMarketingSlotItemComponent,
        CmsEditMarketingSlotItemComponent,
        CmsTranslateMarketingSlotItemComponent,
        CmsResourcePickerModalComponent,
        CmsAddListItemComponent,
        CmsEditListItemComponent,
        CmsAddTicketBlockComponent,
        CmsArchiveTemplateSettingComponent,
        CmsArchiveSettingComponent,
        CmsFragmentRollbackDialogComponent,
        CmsDeleteTicketBlockPromptComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

