import {AfterViewChecked, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {isUndefined} from 'lodash';
import {CmsIsMobileService} from '../ismobile.service';

@Component({
    selector: 'cms-table',
    templateUrl: 'table.component.html'
})

export class CmsTableComponent implements AfterViewChecked {
    @Input() data: Array<Object>;
    @Input() columns: Array<Object>;
    @Input() languages: Array<Object>;
    @Input() layouts: Array<Object>;
    @Input() disabledRowId: number | boolean = null;
    @Output() copyAction = new EventEmitter<Object>();
    @Output() editAction = new EventEmitter<Object>();
    @Output() deleteAction = new EventEmitter<Object>();
    @Output() languageAction = new EventEmitter<Object>();
    @Output() layoutAction = new EventEmitter<Object>();
    @Output() auditAction = new EventEmitter<Object>();
    @Output() historyAction = new EventEmitter<Object>();
    @ViewChild('table', {static: false}) table;

    touchable: boolean;
    tableResponsive = false;
    responsifyTableAt: number;
    activeDropdownMenu: number = null;

    constructor(private el: ElementRef, private isMobileService: CmsIsMobileService) {
        this.touchable = isMobileService.isTouchDevice();
    }

    ngAfterViewChecked() {
        this.responsify(true);
    };

    @HostListener('window:resize', ['$event'])
    @HostListener('window:orientationchange', ['$event'])
    responsify(runWithTimer) {
        let tableOverflows;

        if (this.responsifyTableAt && this.table.nativeElement.clientWidth > this.responsifyTableAt) {
            tableOverflows = false;
        }
        if (this.el.nativeElement.clientWidth < this.table.nativeElement.clientWidth) {
            tableOverflows = this.el.nativeElement.clientWidth < this.table.nativeElement.clientWidth;
            this.responsifyTableAt = this.table.nativeElement.clientWidth;
        }
        if (!isUndefined(tableOverflows) && tableOverflows !== this.tableResponsive) {
            // When this is called form ngAfterViewChecked we need to ensure that tableResponsive is updated in next
            // tick so that ExpressionChangedAfterItHasBeenCheckedError doesn't occur in dev mode
            if (runWithTimer) {
                setTimeout(_ => this.tableResponsive = tableOverflows);
            } else {
                this.tableResponsive = tableOverflows;
            }
        }
    }

    public fileType(type: string): string {
        if (!type) {
            return '';
        }

        const imageType = /image.*/;
        const videoType = /video.*/;
        const isImage = type.match(imageType);
        const isVideo = type.match(videoType);

        if (isImage) {
            return 'image';
        } else if (isVideo) {
            return 'video';
        } else {
            return 'file';
        }
    }

    getThumbPreviewUrl(row, column) {
        const rowIdArr = column.id.split('|');
        let rowId;
        let url;

        for (let i = 0; i < rowIdArr.length; i++) {
            if (row[rowIdArr[i]]) {
                rowId = row[rowIdArr[i]];
                break;
            }
        }
        url = rowId.replace('(', '%28').replace(')', '%29');
        return url;
    }

    getPreviewUrl (row, column) {
        const rowIdArr = column.id.split('|');
        let rowId;
        let url;

        for (let i = rowIdArr.length; i >= 0; i--) {
            if (row[rowIdArr[i]]) {
                rowId = row[rowIdArr[i]];
                break;
            }
        }
        url = rowId.replace('(', '%28').replace(')', '%29');
        return url;
    }

    copy(row: any, index: number) {
        this.copyAction.emit({row: row, index: index});
    }

    edit(row: any, index: number) {
        this.editAction.emit({ row: row, index: index });
    }

    delete(row: any, index: number) {
        this.deleteAction.emit({ row: row, index: index });
    }

    onLayoutAction(layout: any, row: any) {
        this.layoutAction.emit({layout: layout, row: row});
    }

    audit(row: any, index: number) {
        this.auditAction.emit({row: row, index: index});
    }

    history(row: any, index: number) {
        this.historyAction.emit({row: row, index: index});
    }

    action(action, params) {
        if (this[action]) {
            this[action].apply(this, params);
        }
    }

    onLanguageAction(data) {
        this.languageAction.emit(data);
    }
}
