import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CmsNewCategoryComponent } from './articles/new-category/new-category.component';
import {LoggedInGuard} from './core/logged-in.guard';
import {CmsEditCategoryComponent} from './articles/edit-category/edit-category.component';
import {CmsNewArticleComponent} from './articles/new-article/new-article.component';
import {CmsEditArticleComponent} from './articles/edit-article/edit-article.component';
import {CmsNewAppComponent} from './apps/new-app/new-app.component';
import {CmsEditAppComponent} from './apps/edit-app/edit-app.component';
import {CmsEditArticleTranslationComponent} from './articles/edit-article-translation/edit-article-translation.component';
import {CmsResourcesEditComponent} from './resources/edit-resource/resources-edit.component';
import {CmsEditResourceTranslationComponent} from './resources/edit-resource-translation/edit-resource-translation.component';
import {CmsResourcesUploadComponent} from './resources/upload-resource/resources-upload.component';
import {CmsPageNotFoundComponent} from './page-not-found/page-not-found.component';
import {CmsEditCategoryTranslationComponent} from './articles/edit-category-translation/edit-category-translation.component';
import { CmsEditContainerComponent } from './resources/edit-container/edit-container.component';
import { CmsNewContainerComponent } from './resources/new-container/new-container.component';
import {CmsApplicationSettingsEditComponent} from './application-settings/edit/application-settings-edit.component';
import {CmsApplicationSettingsNewComponent} from './application-settings/new/application-settings-new.component';
import {ModuleAccessGuard} from './core/module-access.guard';
import {CmsNewPageComponent} from './pages/new-page/new-page.component';
import {CmsNewPageTemplateComponent} from './pages/new-page-template/new-page-template.component';
import {CmsEditPageTemplateComponent} from './pages/edit-page-template/edit-page-template.component';
import {CmsTranslatePageTemplateComponent} from './pages/translate-page-template/translate-page-template.component';
import {CmsEditPageComponent} from './pages/edit-page/edit-page.component';
import {CmsTranslatePageComponent} from './pages/translate-page/translate-page.component';
import {CmsNewAppTemplateComponent} from './app-templates/new-app-template/new-app-template.component';
import {CmsEditAppTemplateComponent} from './app-templates/edit-app-template/edit-app-template.component';
import {CmsAppTemplateSettingsEditComponent} from './app-template-settings/edit-app-template-setting/edit-app-template-setting.component';
import {CmsAppTemplateSettingsNewComponent} from './app-template-settings/new-app-template-setting/new-app-template-setting.component';
import { CmsNewFormComponent } from './forms/new-form/new-form.component';
import { CmsNewFormTemplateComponent } from './forms/new-form-template/new-form-template.component';
import { CmsEditFormTemplateComponent } from './forms/edit-form-template/edit-form-template.component';
import { CmsEditFormComponent } from './forms/edit-form/edit-form.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: 'app/layouts/protected/protected.module#CmsProtectedModule'
    },
    {
        path: 'new-category',
        outlet: 'popup',
        component: CmsNewCategoryComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        data: {
            module: 'articles',
            submodule: 'newArticleCategory'
        }
    },
    {
        path: 'edit-category/:id/:localeId',
        outlet: 'popup',
        component: CmsEditCategoryComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        data: {
            module: 'articles',
            submodule: 'articles',
            useEditPermission: true
        }
    },
    {
        path: 'edit-category-translation/:categoryId/:localeId/:translationLocaleId',
        component: CmsEditCategoryTranslationComponent,
        outlet: 'popup',
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        data: {
            module: 'articles',
            submodule: 'articleCategories',
            useEditPermission: true
        }
    },
    {
        path: 'new-app',
        outlet: 'popup',
        component: CmsNewAppComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        data: {
            module: 'applications',
            submodule: 'newApplication',
        }
    },
    {
        path: 'edit-app/:id',
        outlet: 'popup',
        component: CmsEditAppComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        data: {
            module: 'applications',
            submodule: 'applications',
            useEditPermission: true
        }
    },
    {
        path: 'new-app-template',
        outlet: 'popup',
        component: CmsNewAppTemplateComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        data: {
            module: 'applications',
            submodule: 'newApplicationTemplate'
        }
    },
    {
        path: 'edit-app-template/:id',
        outlet: 'popup',
        component: CmsEditAppTemplateComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        data: {
            module: 'applications',
            submodule: 'applicationTemplate',
            useEditPermission: true
        }
    },
    {
        path: 'new-article',
        outlet: 'popup',
        component: CmsNewArticleComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        data: {
            module: 'articles',
            submodule: 'newArticle'
        }

    },
    {
        path: 'edit-article/:id/:localeId',
        component: CmsEditArticleComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'articles',
            submodule: 'articles',
            useEditPermission: true
        }
    },
    {
        path: 'edit-article-translation/:articleId/:localeId/:translationLocaleId',
        component: CmsEditArticleTranslationComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'articles',
            submodule: 'articles',
            useEditPermission: true
        }
    },
    {
        path: 'edit-resource/:id',
        component: CmsResourcesEditComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'resources',
            submodule: 'resources',
            useEditPermission: true
        }
    },
    {
        path: 'new-resource',
        component: CmsResourcesUploadComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'resources',
            submodule: 'uploadResource'
        }
    },
    {
        path: 'edit-resource-translation/:resourceId/:localeName/:localeId',
        component: CmsEditResourceTranslationComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'resources',
            submodule: 'resources',
            useEditPermission: true
        }
    },
    {
        path: 'edit-container/:id',
        component: CmsEditContainerComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'resources',
            submodule: 'containers',
            useEditPermission: true
        }
    },
    {
        path: 'new-container',
        component: CmsNewContainerComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'resources',
            submodule: 'newContainer'
        }
    },
    {
        path: 'application-settings-edit/:settingsId/:key',
        component: CmsApplicationSettingsEditComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'settings',
            submodule: 'applicationSettings',
            useEditPermission: true
        }
    },
    {
        path: 'new-application-setting',
        component: CmsApplicationSettingsNewComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'settings',
            submodule: 'newApplicationSetting'
        }
    },
    {
        path: 'edit-app-template-setting/:settingId',
        component: CmsAppTemplateSettingsEditComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'settings',
            submodule: 'applicationTemplateSettings',
            useEditPermission: true
        }
    },
    {
        path: 'new-app-template-setting',
        component: CmsAppTemplateSettingsNewComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'settings',
            submodule: 'newApplicationTemplateSetting'
        }
    },
    {
        path: 'new-page',
        component: CmsNewPageComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'pages',
            submodule: 'newPage'
        }
    },
    {
        path: 'edit-page/:id',
        component: CmsEditPageComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'pages',
            submodule: 'pages',
            useEditPermission: true
        }
    },
    {
        path: 'translate-page/:pageId/:toLocaleId',
        component: CmsTranslatePageComponent,
        canActivate: [LoggedInGuard],
        outlet: 'popup',
        data: {
            titleTranslationKey: 'pages_translate',
            module: 'pages',
            submodule: 'pages',
            useEditPermission: true
        }
    },
    {
        path: 'new-page-template',
        component: CmsNewPageTemplateComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'pages',
            submodule: 'newPageTemplate'
        }
    },
    {
        path: 'edit-page-template/:id',
        component: CmsEditPageTemplateComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'pages',
            submodule: 'pageTemplates',
            useEditPermission: true
        }
    },
    {
        path: 'translate-page-template/:pageId/:toLocaleId',
        component: CmsTranslatePageTemplateComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            titleTranslationKey: 'pages_template_translate',
            module: 'pages',
            submodule: 'pageTemplates',
            useEditPermission: true
        }
    },
    {
        path: 'new-form',
        component: CmsNewFormComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'applicationForms',
            submodule: 'newApplicationForm'
        }
    },
    {
        path: 'new-form-template',
        component: CmsNewFormTemplateComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'applicationForms',
            submodule: 'newApplicationFormTemplate'
        }
    },
    {
        path: 'edit-form-template/:formTemplateId',
        component: CmsEditFormTemplateComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'applicationForms',
            submodule: 'applicationFormTemplate',
            useEditPermission: true
        }
    },
    {
        path: 'edit-form/:formId',
        component: CmsEditFormComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: {
            module: 'applicationForms',
            submodule: 'applicationForm',
            useEditPermission: true
        }
    },
    {
        path: '**',
        canActivate: [LoggedInGuard],
        component: CmsPageNotFoundComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {}
