import { Routes } from '@angular/router';
import { CmsNewCategoryComponent } from './articles/new-category/new-category.component';
import { LoggedInGuard } from './core/logged-in.guard';
import { CmsEditCategoryComponent } from './articles/edit-category/edit-category.component';
import { CmsNewArticleComponent } from './articles/new-article/new-article.component';
import { CmsEditArticleComponent } from './articles/edit-article/edit-article.component';
import { CmsNewAppComponent } from './apps/new-app/new-app.component';
import { CmsEditAppComponent } from './apps/edit-app/edit-app.component';
import { CmsEditArticleTranslationComponent } from './articles/edit-article-translation/edit-article-translation.component';
import { CmsResourcesEditComponent } from './resources/edit-resource/resources-edit.component';
import { CmsEditResourceTranslationComponent } from './resources/edit-resource-translation/edit-resource-translation.component';
import { CmsResourcesUploadComponent } from './resources/upload-resource/resources-upload.component';
import { CmsPageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CmsEditCategoryTranslationComponent } from './articles/edit-category-translation/edit-category-translation.component';
import { CmsEditContainerComponent } from './resources/edit-container/edit-container.component';
import { CmsNewContainerComponent } from './resources/new-container/new-container.component';
import { CmsApplicationSettingsEditComponent } from './application-settings/edit/application-settings-edit.component';
import { CmsApplicationSettingsNewComponent } from './application-settings/new/application-settings-new.component';
import { ModuleAccessGuard } from './core/module-access.guard';
import { CmsNewPageComponent } from './pages/new-page/new-page.component';
import { CmsNewPageTemplateComponent } from './pages/new-page-template/new-page-template.component';
import { CmsEditPageTemplateComponent } from './pages/edit-page-template/edit-page-template.component';
import { CmsTranslatePageTemplateComponent } from './pages/translate-page-template/translate-page-template.component';
import { CmsEditPageComponent } from './pages/edit-page/edit-page.component';
import { CmsTranslatePageComponent } from './pages/translate-page/translate-page.component';
import { CmsNewAppTemplateComponent } from './app-templates/new-app-template/new-app-template.component';
import { CmsEditAppTemplateComponent } from './app-templates/edit-app-template/edit-app-template.component';
import { CmsAppTemplateSettingsEditComponent } from './app-template-settings/edit-app-template-setting/edit-app-template-setting.component';
import { CmsAppTemplateSettingsNewComponent } from './app-template-settings/new-app-template-setting/new-app-template-setting.component';
import { CmsNewFormComponent } from './forms/new-form/new-form.component';
import { CmsNewFormTemplateComponent } from './forms/new-form-template/new-form-template.component';
import { CmsEditFormTemplateComponent } from './forms/edit-form-template/edit-form-template.component';
import { CmsEditFormComponent } from './forms/edit-form/edit-form.component';
var ɵ0 = {
    module: 'articles',
    submodule: 'newArticleCategory'
}, ɵ1 = {
    module: 'articles',
    submodule: 'articles',
    useEditPermission: true
}, ɵ2 = {
    module: 'articles',
    submodule: 'articleCategories',
    useEditPermission: true
}, ɵ3 = {
    module: 'applications',
    submodule: 'newApplication',
}, ɵ4 = {
    module: 'applications',
    submodule: 'applications',
    useEditPermission: true
}, ɵ5 = {
    module: 'applications',
    submodule: 'newApplicationTemplate'
}, ɵ6 = {
    module: 'applications',
    submodule: 'applicationTemplate',
    useEditPermission: true
}, ɵ7 = {
    module: 'articles',
    submodule: 'newArticle'
}, ɵ8 = {
    module: 'articles',
    submodule: 'articles',
    useEditPermission: true
}, ɵ9 = {
    module: 'articles',
    submodule: 'articles',
    useEditPermission: true
}, ɵ10 = {
    module: 'resources',
    submodule: 'resources',
    useEditPermission: true
}, ɵ11 = {
    module: 'resources',
    submodule: 'uploadResource'
}, ɵ12 = {
    module: 'resources',
    submodule: 'resources',
    useEditPermission: true
}, ɵ13 = {
    module: 'resources',
    submodule: 'containers',
    useEditPermission: true
}, ɵ14 = {
    module: 'resources',
    submodule: 'newContainer'
}, ɵ15 = {
    module: 'settings',
    submodule: 'applicationSettings',
    useEditPermission: true
}, ɵ16 = {
    module: 'settings',
    submodule: 'newApplicationSetting'
}, ɵ17 = {
    module: 'settings',
    submodule: 'applicationTemplateSettings',
    useEditPermission: true
}, ɵ18 = {
    module: 'settings',
    submodule: 'newApplicationTemplateSetting'
}, ɵ19 = {
    module: 'pages',
    submodule: 'newPage'
}, ɵ20 = {
    module: 'pages',
    submodule: 'pages',
    useEditPermission: true
}, ɵ21 = {
    titleTranslationKey: 'pages_translate',
    module: 'pages',
    submodule: 'pages',
    useEditPermission: true
}, ɵ22 = {
    module: 'pages',
    submodule: 'newPageTemplate'
}, ɵ23 = {
    module: 'pages',
    submodule: 'pageTemplates',
    useEditPermission: true
}, ɵ24 = {
    titleTranslationKey: 'pages_template_translate',
    module: 'pages',
    submodule: 'pageTemplates',
    useEditPermission: true
}, ɵ25 = {
    module: 'applicationForms',
    submodule: 'newApplicationForm'
}, ɵ26 = {
    module: 'applicationForms',
    submodule: 'newApplicationFormTemplate'
}, ɵ27 = {
    module: 'applicationForms',
    submodule: 'applicationFormTemplate',
    useEditPermission: true
}, ɵ28 = {
    module: 'applicationForms',
    submodule: 'applicationForm',
    useEditPermission: true
};
var routes = [
    {
        path: '',
        loadChildren: 'app/layouts/protected/protected.module#CmsProtectedModule'
    },
    {
        path: 'new-category',
        outlet: 'popup',
        component: CmsNewCategoryComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        data: ɵ0
    },
    {
        path: 'edit-category/:id/:localeId',
        outlet: 'popup',
        component: CmsEditCategoryComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        data: ɵ1
    },
    {
        path: 'edit-category-translation/:categoryId/:localeId/:translationLocaleId',
        component: CmsEditCategoryTranslationComponent,
        outlet: 'popup',
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        data: ɵ2
    },
    {
        path: 'new-app',
        outlet: 'popup',
        component: CmsNewAppComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        data: ɵ3
    },
    {
        path: 'edit-app/:id',
        outlet: 'popup',
        component: CmsEditAppComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        data: ɵ4
    },
    {
        path: 'new-app-template',
        outlet: 'popup',
        component: CmsNewAppTemplateComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        data: ɵ5
    },
    {
        path: 'edit-app-template/:id',
        outlet: 'popup',
        component: CmsEditAppTemplateComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        data: ɵ6
    },
    {
        path: 'new-article',
        outlet: 'popup',
        component: CmsNewArticleComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        data: ɵ7
    },
    {
        path: 'edit-article/:id/:localeId',
        component: CmsEditArticleComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ8
    },
    {
        path: 'edit-article-translation/:articleId/:localeId/:translationLocaleId',
        component: CmsEditArticleTranslationComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ9
    },
    {
        path: 'edit-resource/:id',
        component: CmsResourcesEditComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ10
    },
    {
        path: 'new-resource',
        component: CmsResourcesUploadComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ11
    },
    {
        path: 'edit-resource-translation/:resourceId/:localeName/:localeId',
        component: CmsEditResourceTranslationComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ12
    },
    {
        path: 'edit-container/:id',
        component: CmsEditContainerComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ13
    },
    {
        path: 'new-container',
        component: CmsNewContainerComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ14
    },
    {
        path: 'application-settings-edit/:settingsId/:key',
        component: CmsApplicationSettingsEditComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ15
    },
    {
        path: 'new-application-setting',
        component: CmsApplicationSettingsNewComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ16
    },
    {
        path: 'edit-app-template-setting/:settingId',
        component: CmsAppTemplateSettingsEditComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ17
    },
    {
        path: 'new-app-template-setting',
        component: CmsAppTemplateSettingsNewComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ18
    },
    {
        path: 'new-page',
        component: CmsNewPageComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ19
    },
    {
        path: 'edit-page/:id',
        component: CmsEditPageComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ20
    },
    {
        path: 'translate-page/:pageId/:toLocaleId',
        component: CmsTranslatePageComponent,
        canActivate: [LoggedInGuard],
        outlet: 'popup',
        data: ɵ21
    },
    {
        path: 'new-page-template',
        component: CmsNewPageTemplateComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ22
    },
    {
        path: 'edit-page-template/:id',
        component: CmsEditPageTemplateComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ23
    },
    {
        path: 'translate-page-template/:pageId/:toLocaleId',
        component: CmsTranslatePageTemplateComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ24
    },
    {
        path: 'new-form',
        component: CmsNewFormComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ25
    },
    {
        path: 'new-form-template',
        component: CmsNewFormTemplateComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ26
    },
    {
        path: 'edit-form-template/:formTemplateId',
        component: CmsEditFormTemplateComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ27
    },
    {
        path: 'edit-form/:formId',
        component: CmsEditFormComponent,
        canActivate: [LoggedInGuard, ModuleAccessGuard],
        outlet: 'popup',
        data: ɵ28
    },
    {
        path: '**',
        canActivate: [LoggedInGuard],
        component: CmsPageNotFoundComponent
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28 };
