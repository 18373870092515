<div class="g-popup popup" (cmsEscapeKey)="closePopup()">
    <header class="g-popup-header">
        <i (click)="closePopup()" class="g-popup-header-close g-icon g-icon-close-a"></i>
        <div class="g-popup-title">{{'containers_edit_container' | translate}}</div>
    </header>
    <div class="g-popup-body">
        <form class="popup-form" novalidate [formGroup]="editContainer" (ngSubmit)="onSubmit(editContainer)">
            <div class="form-body">
                <div class="form-control">
                    <div class="g-input g-input-io"
                         [ngClass]="{filled: editContainer.get('title').value,
                                     invalid: (editContainer.get('title').hasError('required') || editContainer.get('title').hasError('maxlength')) && !editContainer.get('title').pristine}">
                        <input type="text" class="g-input-input" [placeholder]="'common_title' | translate" formControlName="title">
                        <label class="g-input-label">{{'common_title' | translate}}</label>
                        <label class="g-input-validation">
                            <span *ngIf="editContainer.get('title').hasError('required')">
                                {{ 'common_validation_field_required' | translate }}
                            </span>
                            <span *ngIf="editContainer.get('title').hasError('maxlength')">
                                {{'common_validation_maximum_field_length' | translate: titleValidationInterpolationParams}}
                            </span>
                        </label>
                    </div>
                </div>
                <div class="form-control">
                    <div class="g-toggle g-toggle-io">
                        <input class="g-toggle-input" type="checkbox" formControlName="createOptimizedImages">
                        <label class="g-toggle-label">{{'resources_create_optimized_images' | translate}}</label>
                    </div>
                </div>
                <div class="form-control">
                    <div class="g-toggle g-toggle-io">
                        <input class="g-toggle-input" type="checkbox" formControlName="active">
                        <label class="g-toggle-label">{{'common_active' | translate}}</label>
                    </div>
                </div>
            </div>
            <div class="form-footer">
                <div class="form-footer-buttons">
                    <button type="submit" class="form-footer-button g-button g-button-earth"
                            [disabled]="editContainer.invalid">
                        <span class="g-button-title">{{'common_save' | translate}}</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
