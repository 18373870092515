import {of as observableOf} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Injectable, Inject} from '@angular/core';
import {SITE_CONFIG} from './site-config.service';
import {HttpClient} from '@angular/common/http';
import {Md5} from 'ts-md5/dist/md5';
import {Company} from '../shared/common.service';
import {CmsTranslateService} from '../shared/translate.service';
import {ISiteConfig} from './site-config.interface';
import {DOCUMENT} from '@angular/common';
import {each, sortBy, get, isEmpty} from 'lodash';
import {CmsGatewayConfig} from '../gateway/shared/cms-gateway-frame-config';

export interface User {
    companies: Array<Company>;
    email: string;
    firstName: string;
    lastName: string;
    locale: string;
}

export interface AllowedFormats {
     allowedFileFormats?: Array<Object>;
}

export interface Modules {
    resources?: AllowedFormats;
}

export interface ResourceSettings {
    extension?: string;
    mimeType?: string;
}

export interface AppTemplateSettings {
    config: {
        gateway: CmsGatewayConfig
        menu?: {
            priorities?: {
                [key: string]: number
            }
        }
    };
}

export interface Bootstrap {
    user: User;
    translations: Object;
    modules: Modules;
    menus: Menus;
    appTemplateSettings: AppTemplateSettings;
}

export interface Menus {
    [key: string]: any;
}

@Injectable()
export class UserService {

    private loggedIn = false;

    private gravityCmsApiUrl: String = '';

    public data: Bootstrap;

    public gravatarImageUrl = '';

    private gravatarApiUrl = 'https://www.gravatar.com/avatar/';

    private gravatarImageSize = '?s=50';

    public momentLocale;

    constructor(private http: HttpClient,
                @Inject (DOCUMENT) private document,
                @Inject(SITE_CONFIG) private config: ISiteConfig,
                private translateService: CmsTranslateService) {

        this.gravityCmsApiUrl = this.config.gravityCmsApi;
        if (this.config.bootstrapData) {
            this.setData(this.config.bootstrapData);
        }
    }

    private _getLocalAppTemplateSettings() {
        const appTemplateSettingsKeys = Object.keys(this.data.appTemplateSettings);
        const localAppTemplateSetting = {};

        for (const key of appTemplateSettingsKeys) {
            try {
                const value = JSON.parse(localStorage.getItem(key));

                if (value !== null) {
                    localAppTemplateSetting[key] = value
                }
            } catch (e) {
                console.log(`CMS: error in parsing config storage key ${key}`, e);
            }
        }

        return localAppTemplateSetting;
    }

    private _setLocalAppTemplateSettings() {
        const localAppTemplateSettings = this._getLocalAppTemplateSettings();

        each(localAppTemplateSettings, (value, key) => {
            if (!isEmpty(value)) {
                this.data.appTemplateSettings[key] = value;
            }
        })
    }

    login(data) {
        return this.http.post(this.gravityCmsApiUrl + '/cms/login', data, {observe: 'response'}).pipe(
            map((response: any) => {
            localStorage.setItem('cms.auth_token', 'Bearer ' + response.headers.get('Access-Token'));
            this.setData(response.body);
            return response.body;
        }));
    }
    logout() {
        return this.http.post(this.gravityCmsApiUrl + '/cms/logout', {}).pipe(
            map(response => {
                localStorage.removeItem('cms.auth_token');
                this.loggedIn = false;
                this.data = null;
                this.gravatarImageUrl = '';
                return response;
            }));
    }

    isLoggedIn() {
        return this.loggedIn;
    }

    reCheckLoggedInState() {
        return this.http
            .get(this.gravityCmsApiUrl + '/cms/login').pipe(
            map(response => {
                const headers = JSON.parse(JSON.stringify(response));
                localStorage.setItem('cms.auth_token', 'Bearer ' + headers.get('Access-Token'));
                this.setData(response);
                return true;
            }),
            catchError(() => {
                this.loggedIn = false;
                return observableOf(false);
            }), );

    }

    setData(data) {
        // localStorage.setItem('cms.auth_token', 'Bearer ' + response.headers.get('Access-Token'));
        this.loggedIn = true;
        this.data = data;
        this._injectMomentLocale(this.data.user.locale);
        this._setMomentLocale(this.data.user.locale);
        this._setLocalAppTemplateSettings();

        if (!this.translateService.hasTranslations()) {
            this.translateService.setTranslations(this.data.translations);
        }

        if (!this.gravatarImageUrl) {
            this.setGravatarImage(this.data.user.email);
        }

        this.data.menus = this._createMainMenu(this.data.menus, this.data.modules);
    }

    getToken(): string {
        return localStorage.getItem('cms.auth_token');
    }

    private _setMomentLocale(locale: string) {
        const momentLocaleMap = {
            'mk-Cyrl': 'mk',
            'mk-Latn': 'mk'
        };
        if (momentLocaleMap[locale]) {
            locale = momentLocaleMap[locale];
        }

        this.momentLocale = locale;
    }

    private _injectMomentLocale(locale: string) {
        if (locale === 'en') {
            return;
        }

        const script = this.document.createElement('script');

        script.src = `/locale/moment/${locale}.js`;
        script.type = 'text/javascript';
        script.async = true;
        script.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    private _createMainMenu = function (menus, modules) {
        const modulePriorities = get(this.data, 'appTemplateSettings.config.menu.priorities') || {};
        let mainMenu = [];

        each(menus, (menu) => {
            const items = [];

            menu.id = menu.name;
            menu.name = this.translateService.translate(menu.name + '_' + menu.name);

            each(menu.items, (item) => {
                const submodule = modules[menu.id].submodules[item.customData.submoduleId];
                const hasRole = submodule ? submodule.view : false;

                if (hasRole && !item.disabled) {
                    item.customData.name = this.translateService.translate(item.customData.name);
                    items.push(item.customData);
                }
            });

            if (items.length) {
                menu.items = items;
                mainMenu.push(menu);
            }
        });

        mainMenu = sortBy(mainMenu, [(menu) => {
            return modulePriorities[menu.id];
        }]);

        return mainMenu;
    };

    setGravatarImage(email: string) {
        const filteredEmail = email.replace(/\+.*@/, '@').toLowerCase().trim();
        const emailHash = Md5.hashStr(filteredEmail);
        this.gravatarImageUrl = this.gravatarApiUrl + emailHash + this.gravatarImageSize;
    }

    /**
     * Check does user have permission for action based
     * on module and submodule id
     *
     * @param {string} moduleId - module id defined inside modules.js on backend
     * @param {string} submoduleId - submodule id defined inside current module on backend
     * @param {string} [role] - 'view', 'edit' or 'archive' defined inside submodule on backend
     * @return {boolean}
     */
    hasRole(moduleId: string, submoduleId: string, role: 'view' | 'edit' | 'archive' = 'view') {
        if (!this.data.modules[moduleId]) {
            return false;
        }

        const foundModule = this.data.modules[moduleId];

        if (foundModule.hasOwnProperty('submodules')) {
            for (const submodule in foundModule.submodules) {
                if (submodule === submoduleId && foundModule.submodules[submodule][role]) {
                    return true;
                }
            }
        }

        return false;
    }
}
