<table class="g-table-andromeda cms-table"
       [ngClass]="{'responsive-table': tableResponsive}"
       #table>
    <thead>
        <tr>
            <ng-container *ngFor="let column of columns">
                <th *ngIf="!column.icons">
                    {{column.name}}
                </th>
                <ng-container *ngFor="let icon of column.icons | slice:0:4;">
                    <th>
                    </th>
                </ng-container>
            </ng-container>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of data; let rowIndex = index;">
            <ng-container *ngIf="tableResponsive">
                <div class="table-actions table-actions-{{rowIndex}}">
                    <div class="table-actions-content"
                         [exclude]="'.table-actions-' + rowIndex"
                         [attachOutsideOnClick]="activeActionsMenuItem == rowIndex"
                         (cmsClickOutside)="activeActionsMenuItem = -1">
                        <div class="table-actions-icon g-icon g-icon-menu-c"
                             (click)="activeActionsMenuItem = rowIndex">
                        </div>
                        <div class="table-actions-menu box-shadow-container"
                             *ngIf="activeActionsMenuItem == rowIndex">
                            <ng-container *ngFor="let column of columns">
                                <ng-container *ngIf="column.icons">
                                    <div class="table-actions-item"
                                         *ngFor="let icon of column.icons"
                                         (click)="action(icon.type, [row, rowIndex]); activeActionsMenuItem = -1">
                                        <a *ngIf="row.url && icon.type.toLowerCase() === 'download'"
                                           [attr.href]="row?.url"
                                           download="">
                                            {{icon.tooltip}}
                                        </a>
                                        <span [innerHTML]="icon.tooltip"
                                              *ngIf="!row.url || icon.type.toLowerCase() !== 'download'">
                                        </span>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngFor="let column of columns; let columnIndex = index; let lastCol = last;">
                <td *ngIf="!column.icons"
                    [ngSwitch]="column.type"
                    [attr.data-responsive-title]="column.name"
                    [ngClass]="column.classes">
                    <div *ngSwitchCase="'multi-text'">
                        <div *ngFor="let item of column.items; let first = first"
                             [class.font-weight-bold]="first">
                            {{row[column.id][item]}}
                        </div>
                    </div>
                    <span *ngSwitchCase="'activity'">
                        <i class="table-icon g-icon"
                        [ngClass]="{'g-icon-check text-success': row[column.id],
                                    'g-icon-close text-danger': !row[column.id]}">
                        </i>
                    </span>
                    <div *ngSwitchCase="'image|file|video'"
                         class="preview">
                        <div *ngIf="fileType(row[column.mimeType]) === 'image'"
                             class="img-preview"
                             cmsImgPreview
                             [imgUrl]="getPreviewUrl(row, column)"
                             [cmsImgCentered]="tableResponsive"
                             [style.background-image]="'url(' + getThumbPreviewUrl(row, column) + ')'">
                        </div>
                        <div *ngIf="fileType(row[column.mimeType]) === 'file'"
                             class="file-preview">
                            <div class="icon">
                                <i class="g-icon g-icon-full-box"></i>
                            </div>
                        </div>
                        <div *ngIf="fileType(row[column.mimeType]) === 'video'"
                             class="video-preview">
                            <div class="icon">
                                <i class="g-icon g-icon-play-a"></i>
                            </div>
                        </div>
                    </div>
                    <cms-table-languages-cell *ngSwitchCase="'translate'"
                                              [languages]="languages"
                                              [row]="row"
                                              (languageAction)="onLanguageAction($event)">
                    </cms-table-languages-cell>
                    <span *ngSwitchCase="'date'" [title]="row[column.id] | date:'medium'">
                        {{row[column.id] | timeAgo}}
                    </span>
                    <span *ngSwitchCase="'layouts'" class="table-layouts-cell">
                        <div *ngFor="let layout of row.layouts"
                                (click)="onLayoutAction(layout, row)"
                                class="layout layout-{{layout.name}}"
                                [ngClass]="{disabled: row.layoutEditDisabled}">
                        </div>
                    </span>
                    <span *ngSwitchDefault
                          class="{{column.style}}">
                        {{column.useObjectKey ? row[column.id] && row[column.id][column.useObjectKey] : row[column.id]}}
                    </span>
                </td>
                <ng-container *ngIf="column.icons && !tableResponsive">
                    <!-- Iterate over passed icons (actions).
                         Show only first three actions.
                         Every other action will be added to dropdown.
                    -->
                    <td *ngFor="let icon of column.icons | slice:0:3; let i=index;"
                        [ngClass]="icon.classes">
                        <i *ngIf="icon.type === 'copy'"
                           class="table-icon-clickable table-icon-hidden g-icon g-icon-copy-link-a"
                           [ngClass]="{ touchable: touchable }"
                       (click)="copy(row, rowIndex)"
                           cmsTooltip [tooltipContent]="icon.tooltip">
                        </i>
                        <i *ngIf="icon.type === 'edit'"
                           class="table-icon-clickable table-icon-hidden g-icon g-icon-edit"
                           [ngClass]="{ touchable: touchable, disabled : row.id === disabledRowId || row.editDisabled }"
                       (click)="edit(row, rowIndex)"
                           cmsTooltip [tooltipContent]="icon.tooltip">
                        </i>
                        <i *ngIf="icon.type === 'delete'"
                           class="table-icon-clickable table-icon-hidden g-icon g-icon-trash"
                           [ngClass]="{ touchable: touchable, disabled : row.id === disabledRowId || row.editDisabled }"
                       (click)="delete(row, rowIndex)"
                           cmsTooltip [tooltipContent]="icon.tooltip">
                    </i>
                    <i *ngIf="icon.type === 'audit' && i<3;"
                       [ngStyle]="{'display': row.editDisabled ? 'none' : 'inline'}"
                       [ngClass]="{ touchable: touchable, disabled : row.id === disabledRowId }"
                       class="table-icon-clickable table-icon-hidden g-icon g-icon-user-a"
                       (click)="audit(row, rowIndex)"
                       cmsTooltip [tooltipContent]="icon.tooltip">
                    </i>
                    <i *ngIf="icon.type === 'history' && i<3;" 
                        [ngStyle]="{'display': row.editDisabled ? 'none' : 'inline'}"
                        [ngClass]="{ touchable: touchable, disabled : row.id === disabledRowId }"
                        class="table-icon-clickable table-icon-hidden g-icon g-icon-table-b" 
                        (click)="history(row, rowIndex)" 
                        cmsTooltip [tooltipContent]="icon.tooltip">
                    </i>
                    <a *ngIf="row.url"
                       [attr.href]="row?.url"
                       download="">
                        <i *ngIf="icon.type === 'download'"
                           class="table-icon-clickable table-icon-hidden g-icon g-icon-download"
                           [ngClass]="{ touchable: touchable }"
                       (click)="copy(row, rowIndex)"
                           cmsTooltip [tooltipContent]="icon.tooltip">
                        </i>
                    </a>
                    </td>
                </ng-container>
                <!-- Add other actions in dropdown
                -->
                <ng-container *ngIf="column.icons && column.icons.length > 3 && !tableResponsive">
                    <td class="table-icon-cell">
                        <div class="table-dropdown-wrapper">
                            <i class="table-icon-clickable table-icon-hidden table-icon-lighter g-icon g-icon-menu-c"
                               (click)="activeDropdownMenu = activeDropdownMenu == rowIndex ? null : rowIndex"
                               [exclude]="'.action-dropdown-' + rowIndex"
                               [attachOutsideOnClick]="activeDropdownMenu == rowIndex"
                               (cmsClickOutside)="activeDropdownMenu = null"
                               [ngClass]="{ 'touchable': touchable}">
                            </i>
                            <div class="action-dropdown action-dropdown-{{dropDownIconIndex}} box-shadow-container"
                                 *ngIf="activeDropdownMenu === rowIndex ">
                                <ng-container *ngFor="let dropDownIcon of column.icons | slice:3:column.icons.length;">
                                    <a class="action-dropdown-item"
                                       *ngIf="dropDownIcon.type === 'download' && activeDropdownMenu == rowIndex"
                                       [attr.href]="row?.url"
                                       download="">
                                        <div (click)="copy(row, rowIndex)"
                                             class="action-dropdown-row">
                                            <span>{{dropDownIcon.tooltip}}</span>
                                        </div>
                                    </a>
                                    <a class="action-dropdown-item"
                                       *ngIf="dropDownIcon.type === 'audit' && activeDropdownMenu == rowIndex">
                                        <div (click)="audit(row, rowIndex)"
                                             class="action-dropdown-row">
                                            <span>{{dropDownIcon.tooltip}}</span>
                                        </div>
                                    </a>
                                </ng-container>
                            </div>
                        </div>
                    </td>
                </ng-container>
            </ng-container>
        </tr>
    </tbody>
</table>
