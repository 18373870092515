export const environment = {
  name: 'production',
  production: true,
  googleAnalytics: true,
  gravityCmsApi: 'https://gravity-service.7platform.com/v1',
  authApi: 'https://services.7platform.com',
  hosts: {
    'cms-rs.7platform.com': 'rs',
    'cms.balkanbet.rs': 'rs',
    'cms-ro.7platform.com': 'ro',
    'cms-ro2.7platform.com': 'ro2',
    'cms-rs2.7platform.com': 'rs2',
    'cms-de.7platform.com': 'de'
  }
};

