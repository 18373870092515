/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../iscroll/iscroll.directive";
import * as i3 from "../../../ismobile.service";
import * as i4 from "../../../click-outside/click-outside.directive";
import * as i5 from "./table-cell-languages.component";
var styles_CmsTableCellLanguagesComponent = [];
var RenderType_CmsTableCellLanguagesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CmsTableCellLanguagesComponent, data: {} });
export { RenderType_CmsTableCellLanguagesComponent as RenderType_CmsTableCellLanguagesComponent };
function View_CmsTableCellLanguagesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "language pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLanguageSelect(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { hidden: 0 }), (_l()(), i0.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "language pointer"; var currVal_1 = _ck(_v, 3, 0, (_co.row.locale && (_v.context.$implicit.id === _co.row.locale.id))); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.title); _ck(_v, 4, 0, currVal_2); }); }
function View_CmsTableCellLanguagesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "language-list"], ["cmsIscroll", ""]], [[2, "cms-iscroll", null]], null, null, null, null)), i0.ɵdid(1, 12730368, null, 0, i2.CmsIscrollDirective, [i0.ElementRef, i3.CmsIsMobileService], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "language-list-scroller"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CmsTableCellLanguagesComponent_2)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.languages; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).isDesktop; _ck(_v, 0, 0, currVal_0); }); }
export function View_CmsTableCellLanguagesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "translate pointer"]], null, [[null, "click"], [null, "cmsClickOutside"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.opened = !_co.opened) !== false);
        ad = (pd_0 && ad);
    } if (("cmsClickOutside" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "language-list-opened": 0 }), i0.ɵdid(4, 671744, null, 0, i4.CmsClickOutsideDirective, [i0.ElementRef, i1.DOCUMENT], { attachOutsideOnClick: [0, "attachOutsideOnClick"] }, { cmsClickOutside: "cmsClickOutside" }), (_l()(), i0.ɵeld(5, 0, null, null, 0, "i", [["class", "translate-icon g-icon g-icon-globe"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CmsTableCellLanguagesComponent_1)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "translate pointer"; var currVal_1 = _ck(_v, 3, 0, _co.opened); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.opened; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.opened; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_CmsTableCellLanguagesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cms-table-languages-cell", [], null, null, null, View_CmsTableCellLanguagesComponent_0, RenderType_CmsTableCellLanguagesComponent)), i0.ɵdid(1, 49152, null, 0, i5.CmsTableCellLanguagesComponent, [], null, null)], null, null); }
var CmsTableCellLanguagesComponentNgFactory = i0.ɵccf("cms-table-languages-cell", i5.CmsTableCellLanguagesComponent, View_CmsTableCellLanguagesComponent_Host_0, { languages: "languages", row: "row" }, { languageAction: "languageAction" }, []);
export { CmsTableCellLanguagesComponentNgFactory as CmsTableCellLanguagesComponentNgFactory };
